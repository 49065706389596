



















import Vue from 'vue'
import { formField } from '@/lib/utils'

export default Vue.extend({
	name: 'ProfileFieldsList',
	props: {
		field: String,
		profile: Object as () => Collection,
		value: Array as () => Collection[],
		fields: Array as () => Collection[],
	},
	computed: {
		matchField(): Collection {
			if (this.field) {
				let matchField = formField(this.field, this.profile[this.field], this.profile, this.fields)
				if (matchField) {
					return matchField
				}
			}
			return { icon: null }
		},
		columns(): Collection[] {
			let columns: Collection[] = []
			let matchField = formField(this.field, this.profile[this.field], this.profile, this.fields)
			if (matchField && matchField.value) {
				return matchField.value
			}

			return columns
		},
		items(): Collection[] {
			let rows: Collection[] = []
			if (this.profile && this.field && this.profile[this.field]) {
				for (const field of this.profile[this.field]) {
					const row: string[] = []
					for (const column of this.columns) {
						if (field[column.name]) {
							if (column.children) {
								if (column.children[field[column.name]]) {
									row.push(column.children[field[column.name]])
								}
							} else {
								let v = field[column.name]
								try {
									let p = JSON.parse(field[column.name])
									v = `${p.label} (${p.value})`
								} catch (error) {

								}
								row.push(v)
							}
						} else {
							let _key = Object.keys(column)[0]
							row.push(field[_key])
						}
					}
					rows.push(row)
				}
			}
			return rows
		},
	},
})
