
















































import Vue from 'vue'
import { MDCList } from '@material/list'
import fieldMap from '@/lib/filters/fieldMap'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default Vue.extend({
	name: 'ListPatients',
	components: {
		LoadingSpinner,
	},
	props: {
		patients: Array,
		provider: Object,
		isLoading: Boolean,
	},
	data: () : {
		list: MDCList | undefined,
	} => ({
		list: undefined
	}),
	mounted() {
		this.list = new MDCList(this.$el)
		this.list.singleSelection = true
		this.list.listen('MDCList:action', () => {
			this.$emit('change', this.list?.selectedIndex)
		})
	},
	computed: {
		items(): Collection[] {
			return (this.patients as Collection[]).map((p: Collection) => fieldMap(p, this.provider.key, true))
		},
	},
})
