

































































































































































































































































































































import Vue from 'vue'
import parse from 'date-fns/parse'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import differenceInYears from 'date-fns/differenceInYears'
import { Store } from 'vuex'
import { Route } from 'vue-router'
import { CheckinStatus } from '@/types/types'
import { covidFields } from '@/lib/utils'

import AgreementsFields from '@/components/AgreementsFields.vue';
import Card from '@/components/Card.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ProfileFields from '@/components/ProfileFields.vue'
import ProfileFieldsList from '@/components/ProfileFieldsList.vue'
import StatusChip from '@/components/StatusChip.vue'
import ModalMessage from '@/components/ModalMessage.vue'
import ModalTransferConfirm from '@/components/ModalTransferConfirm.vue'

export default Vue.extend({
	name: 'PartialCheckin',
	components: {
		AgreementsFields,
		Card,
		LoadingSpinner,
		ModalTransferConfirm,
		ProfileFields,
		ProfileFieldsList,
		StatusChip,
		ModalMessage,
	},
	asyncData: async (store: Store<any>, route: Route): Promise<any> => {
		await store.dispatch('checkin/loadForms')
		return await store.dispatch('checkin/loadCheckin', route.params.id)
	},
	mounted() {
		this.$store.dispatch('checkin/loadCheckin', this.$route.params.id)
	},
	destroyed() {
		this.$store.dispatch('checkin/unloadCheckin')
	},
	data: () : {
		checkinStatus: Collection | null,
		messageToggle: Boolean,
		confirmToggle: Boolean,
		covidQuestions: Collection[],
		// covidFields: Collection[],
	} => ({
		checkinStatus: {
			New: CheckinStatus.New,
			Accepted: CheckinStatus.Accepted,
			Imported: CheckinStatus.Imported,
			Transferred: CheckinStatus.Transferred,
		},
		messageToggle: false,
		confirmToggle: false,
		covidQuestions: covidFields,
		// covidFields,
	}),
	computed: {
		checkin(): Collection {
			const checkin = this.$store.getters['checkin/checkin']
			return checkin
		},
		forms(): Collection[] {
			const forms = this.$store.getters['checkin/forms']
			console.log('forms', forms)

			return forms
		},
		formsFields(): string[] {
			const formsFields = this.$store.getters['checkin/formsFields']
			return formsFields
		},
		formsFieldsMods(): Collection[] {
			const formsFieldsMods = this.$store.getters['checkin/formsFieldsMods']
			return formsFieldsMods
		},
		newFormsFields(): Collection[] {
			const newFormsFields = this.$store.getters['checkin/newFormsFields']
			return newFormsFields
		},
		loading(): boolean {
			return this.$store.getters['checkin/loading']
		},
		settings(): Collection {
			return this.$store.getters['user/settings']
		},
		insuranceFields(): string[] {
			if (this.checkin.insuranceUnavailable) {
				return ['insuranceUnavailable']
			}

			return ['insuranceProvider', 'insuranceType', 'insuranceGroup', 'insuranceFirstName',
		'insuranceLastName','insuranceDob','insuranceGender','insuranceNumber','insuranceDate','insurancePhone','insurancePhotoFront','insurancePhotoBack']
		},
		primaryCareFields(): string[] {
			if (this.checkin.primaryUnavailable) {
				return ['primaryUnavailable']
			}

			return ['primaryName', 'primaryPhone', 'primaryCity', 'primaryState']
		},
		responsiblePartyFields(): string[] {
			if (this.checkin.responsibleParty) {
				return ['responsibleParty']
			}

			return ['responsibleFirstName', 'responsibleMiddleName', 'responsibleLastName', 'responsibleDob', 'responsibleSsn', 'responsibleStreet', 'responsibleStreet2', 'responsibleState', 'responsibleCity', 'responsibleZip', 'responsibleCountry', 'responsibleEmail', 'responsiblePhone', 'responsiblePhone2', 'responsibleRelationship']
		},
		covidFields(): string[] {
			// if (!this.checkin.covidTest) {
			// 	return ['covidTest']
			// }

			return ['covidTest', 'covidTestDate', 'covidTestType', 'covidTestResult', 'covidVaccine', 'covidVaccineBrand', 'covidVaccineDate1', 'covidVaccineDate2', 'covidCardFront', 'covidCardBack']
		},
		covidScreen(): string[] {
			let list: string[] = []
			for (const field of this.covidQuestions) {
				list.push(field.name)
			}
			return list
		},
		agreementsList(): Collection[] {
			const list: Collection[] = []

			this.checkin?.agreements?.forEach((agreement: Collection) => {
				list.push({
					...agreement,
					signature: agreement.signed ? this.checkin.signature : null,
				})
			})

			return list
		},
		user(): Collection {
			const user = this.$store.getters['user/user']
			return user
		},
		provider(): Collection {
			const provider = this.$store.getters['user/provider']
			return provider
		}
	},
	methods: {
		acceptCheckin(checkin: Collection) {
			this.$store.dispatch('checkin/acceptCheckin', checkin)
		},
		deleteCheckin(checkin: Collection) {
			this.$confirm(`Delete Check-In for ${checkin.firstName} ${checkin.lastName}?`, () => {
				this.$store.dispatch('checkin/deleteCheckin', checkin).then(() => {
					this.$router.push({ name: 'main' })
				})
			})
		},
		transferCheckin(checkin: Collection) {

				// Flatten office specific data
				try {
					const officeKey = this.user?.location?.id
					if (officeKey && checkin[officeKey]) {
						let specificFields = checkin[officeKey]
						// delete checkin[officeKey]
						checkin = {...checkin, ...specificFields}
					}
				} catch (error) {
					// Fail Silently
				}

				const provider = this.$store.getters['user/provider']
				if (provider && provider.key) {
					const authToken = this.$store.getters[`${provider.key}/token`]
					if (!authToken) {
						return this.$confirm(`Please make sure you are connected to ${provider.name}.`, () => {
							this.$router.push({ name: 'account' })
						}, {
							title: `Connect to EMR`,
							acceptLabel: `Check Connection`,
						})
					}

					this.confirmToggle = true
					this.$store.dispatch(`${provider.key}/getPatients`, checkin)
						.then((res) => {
						})
						.catch((error) => {
							this.$notice(`ERROR: Can not load patients. ${error.message || error}`)
						})
				} else {
					this.$confirm(`This will transfer ${checkin.firstName} ${checkin.lastName} to your EMR. Please confirm information is accurate.`, async () => {
						this.$store.dispatch('checkin/transferCheckin', checkin)
					}, {
						acceptLabel: 'Yes, Transfer',
						title: `Transfer Patient to EMR`,
					})
				}

		},
		scrollTo(id: string, adjust: boolean = true) {
			const target: HTMLElement | null = document.getElementById(id)
			let timer: number = 0

			function handleScroll(event: Event) {
				clearTimeout(timer)

				timer = window.setTimeout(() => {
					window.scrollBy(0, (-64 * 3))
					document.removeEventListener('scroll', handleScroll)
				}, 66)
			}

			if (target) {
				if (adjust) {
					document.addEventListener('scroll', handleScroll, false)
				}
				target.scrollIntoView({ block: 'start', inline: 'nearest' })
			}
		},
		print() {
			window.print()
		},
		bgImg(src: string) {
			if (!src) return ''
			return `url(data:image/jpg;base64,${src})`
		},
	},
	filters: {
		age(val: string): number {
			const parsed = parse(val, 'MM/dd/yyyy', (new Date()))
			return Math.abs(differenceInYears(parsed, (new Date())) )
		},
	},
	metaInfo() {
		return {
			title: `${(this as any).checkin.firstName} ${(this as any).checkin.lastName}`
		}
	}
})
