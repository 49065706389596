














































import Vue from 'vue'
import { MDCSelect } from '@material/select'

const uuidv4 = require('uuid/v4')

export default Vue.extend({
	name: 'InputSelect',
	props: {
		label: String,
		options: {
			type: Array,
			default: () => ([]),
		},
		id: {
			type: String,
			default: uuidv4(),
		},
		name: String,
		small: Boolean,
	},
	computed: {
		attrs(): Record<string, string> {
			return this.$attrs
		},
		listeners(): Record<string, Function> {
			return {
				...this.$listeners,
				input: (event: Event) => this.input(event),
				change: (event: Event) => this.input(event),
			}
		},
		hasPre(): boolean {
			return Boolean(this.$slots.pre)
		},
		selected(): string {
			const found: Collection | unknown = this.options.find((option) => (option as Collection).value == this.attrs.value)
			if (found) {
				return (found as Collection).label
			}
			return ''
		},
	},
	data: () : {
		select: MDCSelect | any
	} => {
		return {
			select: undefined,
		}
	},
	mounted() {
		this.select = new MDCSelect(this.$el)
		this.select.listen('MDCSelect:change', () => {
			let _selected: Collection | unknown = this.options[this.select.selectedIndex]
			if (_selected) {
				this.$emit('input', (_selected as Collection).value)
			}
		})

		setTimeout(() => {
			// this.$el.querySelector('#selected-text').innerHTML = this.selected
			// if (this.selected) {
			// 	this.$el.querySelector('#select-label').classList.add('mdc-floating-label--float-above')
			// }
		}, 250)
	},
	methods: {
		input(event: Event): void {
			if (event && event.target) {
				const target = event.target as HTMLInputElement
				this.$emit('input', target.value)
			}
		}
	},
})
