









import Vue from 'vue'
import { MDCTextField } from '@material/textfield'

const uuidv4 = require('uuid/v4')

export default Vue.extend({
	name: 'InputTextarea',
	data: () : {
		textfield: MDCTextField | any
	} => {
		return {
			textfield: undefined,
		}
	},
	props: {
		autocomplete: String,
		id: {
			type: String,
			default: uuidv4(),
		},
		label: String,
		name: String,
		placeholder: String,
		type: String,
		minlength: {
			type: [Number, String],
			default: null,
		},
		maxlength: {
			type: [Number, String],
			default: null,
		},
		rows: {
			type: [Number],
			default: 8,
		},
		cols: {
			type: Number,
			default: 40,
		},
		small: Boolean,
		ripple: Boolean,
	},
	computed: {
		attrs(): Record<string, string> {
			return this.$attrs
		},
		listeners(): Record<string, Function> {
			return {
				...this.$listeners,
				input: (event: Event) => this.input(event),
			}
		},
		hasPre(): boolean {
			return Boolean(this.$slots.pre)
		},
		hasPost(): boolean {
			return Boolean(this.$slots.post)
		},
	},
	mounted() {
		this.textfield = new MDCTextField(this.$el)
		if (this.textfield && this.attrs.value) {
			this.textfield.value = this.attrs.value
		}
	},
	methods: {
		input(event: Event): void {
			if (event && event.target) {
				const target = event.target as HTMLInputElement
				this.$emit('input', target.value)
			}
		}
	},
	inheritAttrs: false,
})
