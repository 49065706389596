
























import Vue from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import Modal from '@/components/Modal.vue'
import ListPatients from '@/components/ListPatients.vue'
import InputSelect from '@/components/InputSelect.vue'
import { CheckinStatus } from '@/types/types'
import { format } from 'date-fns'


export default Vue.extend({
	name: 'ModalTransferConfirm',
	components: {
		InputSelect,
		ListPatients,
		LoadingSpinner,
		Modal,
	},
	props: {
		open: Boolean,
		checkin: Object,
		provider: Object,
	},
	data: () : {
		isLoading: Boolean,
		isSelected: Boolean,
		patients: Collection[],
		doctors: Collection[],
		selectedIdx: number,
	} => ({
		isLoading: false,
		isSelected: false,
		patients: [],
		doctors: [],
		selectedIdx: -1,
	}),
	mounted() {
		// this.getPatients()
	},
	computed: {
		user(): Collection {
			return this.$store.getters['user/user']
		},
		doctorOptions(): Collection[] {
			let options: Collection[] = []
			if (this.doctors.length) {
				options = this.doctors.map((d: Collection) => ({
					value: d.id,
					label: `Dr. ${d.last_name}, ${d.first_name}`,
				}))
			}
			return options
		},
	},
	methods: {
		getPatients() {
			this.isLoading = true
			this.$store.dispatch(`${this.provider.key}/getPatients`, this.checkin)
				.then((res) => {
					this.patients = res
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})

		},
		getDoctors() {
			this.isLoading = true
			this.$store.dispatch(`${this.provider.key}/getDoctors`, this.checkin)
				.then((res) => {
					this.doctors = res
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		selectedProfile(idx: number) {
			if (idx > -1) {
				this.isSelected = true
			}

			this.selectedIdx = idx
		},
		async transferProfile() {
			this.$confirm(`This will transfer <strong>${this.checkin.firstName} ${this.checkin.lastName}</strong> to your EMR. \n<br><br>Please confirm patient information is accurate.`, async () => {

				this.isLoading = true
				this.checkin.location = this.user.location.id
				if (this.selectedIdx == this.patients.length || (this.patients.length == 0 && this.selectedIdx == 1)) {
					this.$store.dispatch(`${this.provider.key}/addPatient`, { checkin: this.checkin })
						.then((res) => {
							this.checkin.status = CheckinStatus.Transferred
							this.checkin.transferredAt = (new Date()).getTime()
							this.$store.dispatch('checkin/updateCheckin', this.checkin)

							let noticeString = `<li>${(res.notices || []).join(`</li><li>`)}</li>`
							let errorString = `<li>${(res.errors || []).join(`</li><li>`)}</li>`

							this.$confirm(`<strong>${this.checkin.firstName} ${this.checkin.lastName}</strong> (${this.checkin.dob}) has been transferred to your EMR!<ul class="checkin_notices">${noticeString}</ul><ul class="checkin_errors">${errorString}</ul>`, () => {
								// this.$router.back()
							}, {
								title: `Patient Transfer`,
								showCancel: false,
								acceptLabel: `Close`,
							})
						})
						.catch((error) => {
							this.$confirm(`<strong>There was an error transerring this patient: ${error.message || error}</strong>`, () => {

							}, {
								title: `Transfer Failed`,
								showCancel: false,
								acceptLabel: `Close`
							})
						})
						.finally(() => {
							this.isLoading = false
						})
				} else {
					const patient = this.patients[this.selectedIdx]
					this.$store.dispatch(`${this.provider.key}/updatePatient`, { checkin: this.checkin, patient, })
						.then((res) => {
							this.checkin.status = CheckinStatus.Transferred
							this.checkin.transferredAt = (new Date()).getTime()
							this.$store.dispatch('checkin/updateCheckin', this.checkin)
console.log({ res, })
							let noticeString = (res.notices && res.notices.length) ? `<li>${(res.notices || []).join(`</li><li>`)}</li>` : ``
							let errorString = (res.errors && res.errors.length) ? `<li>${(res.errors || []).join(`</li><li>`)}</li>` : ``

							this.$confirm(`<strong>${patient.firstName} ${patient.lastName}</strong> (${this.checkin.dob}) has been transferred to your EMR!<ul class="checkin_notices">${noticeString}</ul><ul class="checkin_errors">${errorString}</ul>`, () => {}, {
								title: `Patient Transfer`,
								showCancel: false,
								acceptLabel: `Close`,
							})
						})
						.catch((error) => {
							this.$confirm(`<strong>There was an error transerring this patient: ${error.message || error}</strong>`, () => {}, {
								title: `Transfer Failed`,
								showCancel: false,
								acceptLabel: `Close`
							})
						})
						.finally(() => {
							this.isLoading = false
						})
				}
			}, {
				acceptLabel: `Yes, Transfer Patient`,
			})
		},
	},
	watch: {
		open(newVal, oldVal) {
			if (newVal) {
				this.getPatients()
				// this.getDoctors()
			}
		},
	},
})
