

















































import Vue from 'vue'
import { v4 } from 'uuid'
import InputSelect from '@/components/InputSelect.vue'
import InputText from '@/components/InputText.vue'
import InputTextarea from '@/components/InputTextarea.vue'
// import CheckinCode from '@/components/CheckinCode.vue'
import LoadingBar from '@/components/LoadingBar.vue'
import Modal from '@/components/Modal.vue'
import TabBar from '@/components/TabBar.vue'

export default Vue.extend({
	name: 'ModalMessage',
	components: {
		// CheckinCode,
		InputSelect,
		InputText,
		InputTextarea,
		LoadingBar,
		Modal,
		TabBar,
	},
	props: {
		open: Boolean,
		checkin: Object,
	},
	data: () : {
		fields: Collection,
		preset: string | undefined,
		presets: Collection[],
		isLoading: Boolean,
		tabs: Collection[],
		activeTab: number,
	} => ({
		fields: {
			to: '',
			body: '',
		},
		preset: undefined,
		presets: [
			{
				label: `Confirm Check In`,
				value: 'confirm',
				body: `You have been checked in! We will let you know when you come to the front desk.`,
			},
			{
				label: `You're Next!`,
				value: `next`,
				body: `You're next! Please come to the front desk for your appointment.`
			},
			{
				label: `Missing Info`,
				value: 'missing',
				body: `Please fill in this missing info in Quick Patient, then check in again: `,
			},
		],
		isLoading: false,
		tabs: [
			{
				action: () => {
					console.log('set')
				},
				label: `Send Message`,
			},
			{
				action() {
					this.activeTab = 1
				},
				label: `History`,
			},
		],
		activeTab: 0,
	}),
	mounted() {
		this.fields.to = this.checkin.phone
	},
	computed: {
		smsMessages(): Collection[] {
			let msgs: Collection[] = []
			if (this.checkin.messages) {
				return this.checkin.messages.sort((a: Collection, b: Collection) => (new Date(a.dateUpdated)).getTime() > (new Date(b.dateUpdated)).getTime() ? -1 : 1 )
			}

			return msgs
		},
 	},
	methods: {
		submitForm(fields: Collection) {
			this.isLoading = true
			this.checkin.messages = this.checkin.messages || []

			this.$store.dispatch('checkin/sendSms', {
				to: fields.to,
				body: fields.body,
			}).then(res => {
				this.$notice('Message sent!', () => {}, {
					id: v4(),
				})

				this.fields.body = ''
				this.preset = undefined

				this.checkin.messages.push(res)
				this.$store.dispatch('checkin/updateCheckin', this.checkin)

				setTimeout(() => {
					this.$emit('close')
				}, 2000)
			}).catch(err => {
				this.$notice(err.message || err, () => {}, {
					id: v4(),
				})
			}).then(() => {
				this.isLoading = false
			})

		},
		setTab(idx: number) {
			this.activeTab = idx
		},
	},
	watch: {
		preset(newVal) {
			this.fields.body = (this.presets.find(p => p.value === newVal) || {}).body
		},
	},
})
