























import Vue from 'vue'
import { jsPDF } from 'jspdf'
import { format } from 'date-fns'

function addWrappedText(
	{text, textWidth, doc, fontSize = 10, fontType = 'normal', lineSpacing = 7, xPosition = 10, initialYPosition = 10, pageWrapInitialYPosition = 10}:
	{text: any, textWidth: number, doc: jsPDF, fontSize?: number, fontType?: string, lineSpacing?: number, xPosition?: number, initialYPosition?: number, pageWrapInitialYPosition?: number}): number {
	// doc.setFontType(fontType);
	doc.setFontSize(fontSize);
	var textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
	var pageHeight = doc.internal.pageSize.height;        // Get page height, we'll use this for auto-paging. TRANSLATE this line if using units other than `pt`
	var cursorY = initialYPosition;

	textLines.forEach((lineText: string) => {
		if (cursorY > pageHeight) { // Auto-paging
			doc.addPage();
			cursorY = pageWrapInitialYPosition;
		}
		doc.text(lineText, xPosition, cursorY);
		cursorY += lineSpacing;
	})
	return cursorY
}

export default Vue.extend({
	name: 'AgreementsFields',
	props: {
		agreements: Array as () => Collection[],
		checkin: Object as () => Collection,
	},
	methods: {
		downloadPdf(agreement: Collection, idx: number) {
			const doc = new jsPDF()

			doc.setFont('courier')
			doc.setFontSize(16)
			doc.text(agreement.title, 10, 10);

			doc.setFontSize(10)
			doc.text(`modified: ${agreement.dateModified}`, 10, 20)

			doc.setFontSize(11)
			let newY = addWrappedText({
				text: agreement.content,
				textWidth: 180,
				doc,
				xPosition: 10,
				initialYPosition: 40,
			})
			// doc.text(doc.splitTextToSize(agreement.content.split('\n'), 60), 10, 40, {
			// 	maxWidth: 180,
			// })

			// doc.addPage()
			doc.setFontSize(11)
			doc.addImage(`data:image/jpg;base64,${agreement.signature}`, 'JPEG', 10, newY + 10, 66, 25)
			doc.setFontSize(14)
			let signed = this.checkin.firstName +' '+ this.checkin.lastName
			if (this.checkin.responsibleFirstName) {
				signed = this.checkin.responsibleFirstName +' '+ this.checkin.responsibleLastName
			}
			doc.text(`${signed}`, 10, newY + 40)
			doc.setFontSize(10)
			doc.text(`(${format(agreement.timestamp, 'LLL dd, yyyy hh:mm:ss a')})`, 10, newY + 46)

			let filename = `${this.checkin.lastName}, ${this.checkin.firstName} - ${agreement.title}.pdf`
			doc.save(filename)
		},
	},
})
