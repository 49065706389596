





















import Vue from 'vue'
import { formField } from '@/lib/utils'
import { v4 } from 'uuid'

export default Vue.extend({
	name: 'ProfileFields',
	components: {
	},
	data: (): {
		copyInput: HTMLInputElement | null
	} => ({
		copyInput: document.querySelector('#copy_input'),
	}),
	props: {
		profile: Object as () => Collection,
		fields: Array as () => string[],
		source: Array as () => Collection[],
	},
	computed: {
		profileFields() {
			let fields: Collection[] = []
			this.fields.forEach((name: string) => {
				fields.push(formField(name, this.profile[name], this.profile, this.source))
			})

			return fields
		},
	},
	mounted() {
		this.copyInput = document.querySelector('#copy_input')
	},
	methods: {
		formField(name: string, value: any): Collection {
			return formField(name, value)
		},
		copyField(field: Collection) {
			if ( (field.type == 'input' || field.type == 'dropdown') && this.copyInput) {
				this.copyInput.value = field.value
				this.copyInput.select()

				let copied
				try {
					copied = document.execCommand('copy')
				} catch (error) { }

				if (copied) {
					this.$notice(`"${field.value}" Copied to clipboard!`, null, { id: v4() })
				}
			}
		}
	}
})
